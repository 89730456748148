<template>
  <div>
   <div class="container">
     <div class="searchForm">
       <el-form ref="form" :model="form" :inline="true"  label-position="left" class="form">
         <el-form-item label="商户号" label-width="60px">
           <el-input type="number" class="inputStyle" v-model="form.num" @input="inputShopId"></el-input>
         </el-form-item>
         <el-form-item label="商户名" label-width="60px">
           <el-input type="text" class="inputStyle" v-model="form.name" maxlength="20"></el-input>
         </el-form-item>
         <el-form-item label="商户电话" label-width="80px">
           <el-input type="number" class="inputStyle" v-model="form.phone" @input="inputPhone"></el-input>
         </el-form-item>
         <el-form-item label="订单状态" label-width="80px">
           <el-select v-model="form.orderStatus" placeholder="请选择">
             <el-option v-for="item in orderStatusList" :key="item.value"
               :label="item.label" :value="item.value">
             </el-option>
           </el-select>
         </el-form-item>
       </el-form>
       <div class="timeAndBtn flexbox flex-lr flex-align-center">
         <div class="timeChoose">时间筛选</div>
         <el-date-picker
           v-model="time"
           type="daterange"
           range-separator="至"
           start-placeholder="开始日期"
           end-placeholder="结束日期">
         </el-date-picker>
         <el-button type="primary" class="sure" @click="screen">筛选</el-button>
         <el-button type="info" class="sure" @click="clear">清空</el-button>
        <PrevReclick >
          <el-button type="primary" class="sure" @click="openExcleVisible('1')">导出外卖数据</el-button>
        </PrevReclick>
        <PrevReclick >
          <el-button type="primary" class="sure" @click="openExcleVisible('2')">导出配送数据</el-button>
        </PrevReclick>
       </div>
     </div>
     <div class="orderExplain flexbox flex-lr flex-align-center flex-justify-end">
       <div class="orderExplainTxt">订单说明</div>
       <img class="question" src="/img/order/explain.png" alt="" @click="showTips">
     </div>
     <el-table :data="tableData" style="width: 100%" border>
       <el-table-column prop="orderNumber" label="订单号" width="180"></el-table-column>
       <el-table-column prop="shopId" label="商户号" width="80"></el-table-column>
       <el-table-column prop="shopName" label="商户名"></el-table-column>
       <el-table-column prop="createTime" label="订单创建时间" width="140">
         <template slot-scope="scope">
           <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm') }}</span>
         </template>
       </el-table-column>
       <el-table-column prop="endTime" label="订单结束时间" width="140">
         <template slot-scope="scope">
           <span>{{ $formatDate(scope.row.endTime, 'yyyy-MM-dd HH:mm') }}</span>
         </template>
       </el-table-column>
       <el-table-column prop="totalPrice" label="订单金额" width="120"></el-table-column>
       <el-table-column prop="amountPaid" label="优惠后支付金额" width="120"></el-table-column>
       <el-table-column prop="distribution" label="配送费" width="120"></el-table-column>
       <el-table-column prop="spreadCommission" label="平台抽佣金额" width="120"></el-table-column>
       <el-table-column prop="shopFinance" label="商户实得收益" width="120"></el-table-column>
       <el-table-column prop="state" label="状态">
         <template slot-scope="scope">
           <span :class="'status'+scope.row.state">{{ orderStatusList[+scope.row.state].label }}</span>
         </template>
       </el-table-column>
     </el-table>
     <el-pagination class="mt10" background :page-count="totalPage"
                    :current-page.sync="currentPage" @current-change="pageChange">
     </el-pagination>
     <Dialog ref="orderDialog"></Dialog>
   </div>
    <el-dialog title="导出数据" :visible.sync="excleVisible" width="400px" >
      <span style="padding-right:10px">选择日期：</span>
      <el-date-picker v-model="excleTime"  type="daterange" range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期" :picker-options="pickerOptions">
      </el-date-picker>
      <p style="padding-top:20px;color:red">注：不能跨月导出数据</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="excleVisible = false">取 消</el-button>
         <PrevReclick>
        <el-button type="primary" @click="expootExcle">确认导出</el-button>
         </PrevReclick>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Dialog from '@/components/common/dialog.vue';
import { $sg_findAgentOrderDTO } from '@/api/order';
import { exportFileSync } from '@base/utils';

export default {
  name: 'orderIndex',
  components: {
    Dialog,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          // 获取2020年12月31日的时间戳
          const startTime = new Date('2020-12-31').getTime();
          // 获取目前时间的时间戳
          const endTime = new Date().getTime();
          // 禁止选择小于2021年1月1日之前和当前日期之后的日期
          return time.getTime() < startTime || time.getTime() > endTime;
        },
      },
      form: {
        num: '',
        name: '',
        phone: '',
        orderStatus: '',
      },
      orderStatusList: [
        { value: '', label: '全部' },
        { value: 1, label: '待接单' },
        { value: 2, label: '骑手待接单' },
        { value: 3, label: '待收货' },
        { value: 4, label: '已完成' },
        { value: 5, label: '撤销' },
      ],
      time: '',
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      excleVisible: false,
      excleTime: null,
      excleType: '', // 1-导出外卖数据，2-导出配送数据
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    // 打开导出数据弹窗
    openExcleVisible(type) {
      this.excleType = type;
      this.excleTime = '';
      this.excleVisible = true;
    },
    // 导出数据
    expootExcle() {
      let startTime = null;
      let endTime = null;
      if (this.excleTime) {
        startTime = this.excleTime[0];
        endTime = this.excleTime[1];
        if (startTime.getMonth() !== endTime.getMonth() || startTime.getFullYear() !== endTime.getFullYear()) {
          this.$message.error('不能跨月导出数据');
          return;
        } else {
          startTime = (this.excleTime[0]).getTime() / 1000 || null; 
          endTime = (this.excleTime[1]).getTime() / 1000 || null;
        }
      } else {
        this.$message.error('请选择时间');
        return;
      }
      if (this.excleType === '1') {
        exportFileSync(
          '/api/agent/biz/exportOrders/merchantOrders',
          {
            startTime,
            endTime,
          },
          'post',
          '商户订单外卖数据.xlsx',
          this.onDownloadProgress,
        ).then(() => {
        // this.handleDownloadQrCodeLeftBtn();
        });
      } else if (this.excleType === '2') {
        exportFileSync(
          '/j/distribution/agent/order/management/exportRiderInfo',
          {
            startTime,
            endTime,
          },
          'post',
          '商户订单配送数据.xlsx',
          this.onDownloadProgress,
        );
      }
    },
    getList() {
      console.log(this.time);
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 15,
        shopId: this.form.num,
        shopName: this.form.name,
        contactTel: this.form.phone,
        state: this.form.orderStatus,
        startTime: this.time.length > 0 ? this.$formatDateByDate(this.time[0], 'yyyy-MM-dd') : '',
        endTime: this.time.length > 0 ? this.$formatDateByDate(this.time[1], 'yyyy-MM-dd') : '',
      };
      $sg_findAgentOrderDTO(params).then((res) => {
        this.tableData = res.list;
        this.totalPage = res.pages;
      });
    },
    showTips() {
      this.$refs.orderDialog.dialogs = {
        icon: '/img/dialog/icon-order.png',
        title2: '订单说明',
        content2: [
          {
            title: '名称定义说明',
            list: ['商户号：商户系统唯一编号，用于商户查询商户筛选等',
              '商户名：商户名为商户店名（大部分为缩写）',
              '商户联系电话：商户预留手机号',
              '分润时间：订单已完成后的2分钟内',
              '订单金额：用户下单时，所有收费总和',
              '实际支付金额：用户抵扣掉优惠券、积分、折扣等实际用户支付金额',
              '配送费用：第三方或商户配送商品费用',
              '抽佣比例：每笔订单平台抽取实际支付金额的比例',
              '平台扣除总金额：实际代理商可参与分润的总金额',
              '我的分润：此笔订单我的分润收益',
              '状态：分润状态'],
          },
        ],
        done: '已知晓',
      };
      this.$refs.orderDialog.showDialog = true;
    },
    pageChange() {
      this.getList();
    },
    screen() {
      this.currentPage = 1;
      this.getList();
    },
    clear() {
      this.form = {
        num: '',
        name: '',
        phone: '',
        orderStatus: '',
      };
      this.time = '';
      this.getList();
    },
    inputPhone(value) {
      if (value.length > 11) {
        this.form.phone = value.slice(0, 11);
      }
    },
    inputShopId(value) {
      if (value.length > 11) {
        this.form.num = value.slice(0, 11);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .container{
    width: 100%;
    min-width: 1400px;
    min-height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 40px 20px;
    box-sizing: border-box;
    background-color: #fff;
  }
  .el-form--inline .el-form-item{
    margin-right: 60px;
  }
  .inputStyle{
    width: 180px;
  }
  .timeChoose{
    width: 80px;
    text-align: left;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
  .sure{
    margin-left: 30px;
  }
  .orderExplain{
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .orderExplainTxt{
    font-size: 14px;
    font-weight: 400;
    color: #F22424;
  }
  .el-table{
    min-height: 580px;
    & > th {
      background-color: #EF3F46;
      color: #fff;
    }
  }
  .question{
    width: 14px;
    height: 14px;
    margin-left: 8px;
    cursor: pointer;
  }
  .status1{
    color: #F22424;
  }
  .status2{
    color: #FA8828;
  }
  .status3{
    color: #13CA7E;
  }
  .status4{
    color: #333;
  }
</style>
